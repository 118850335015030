<template>
  <div id="agentInfo">
    <v-card class="mx-auto" max-width="500" tile v-if="getAgentInfo" light>
      <v-list-item three-line>
        <v-list-item-avatar tile size="80" color="grey">
          <v-img :src="getAgentInfo.avatar"></v-img
        ></v-list-item-avatar>

        <v-list-item-content class="text-left align-self-start">
          <v-list-item-title class="text-h5 mb-1">
            {{ fullName }}
          </v-list-item-title>
          <v-list-item-title class="pt-1 pb-1">
            ตำแหน่ง: {{ getAgentInfo.position || "N/A" }}
          </v-list-item-title>
          <v-list-item-title class="pt-1 pb-1">
            Hub: {{ getAgentInfo.th_station || "N/A" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="goToSettings" text
          >ตั้งค่า<v-icon>mdi-cog-outline</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card-title> </v-card-title>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("agent", ["getAgentInfo"]),
    fullName() {
      return [
        "คุณ",
        this.getAgentInfo.th_firstname,
        this.getAgentInfo.th_lastname,
      ]
        .filter(Boolean)
        .join(" ");
    },
  },
  mounted() {
    this.fetchUserProfile();
  },
  methods: {
    ...mapActions("agent", ["fetchUserProfile"]),
    goToSettings() {
      this.$router.push({ name: "settings" });
    },
  },
};
</script>

<style></style>
